import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import RuSvg from './svg/ru.svg';
import UsSvg from './svg/us.svg';
import EsSvg from './svg/es.svg';
import ZhSvg from './svg/cn.svg';
import HiSvg from './svg/hi.svg';
import FrSvg from './svg/fr.svg';
import KoSvg from './svg/ko.svg';
import DeSvg from './svg/de.svg';
import JaSvg from './svg/jp.svg';
import PtSvg from './svg/po.svg';
import TrSvg from './svg/tr.svg';
import UkSvg from './svg/uk.svg';
import ArrowSvg from './svg/arrow.svg';
import { useCookies } from 'next-client-cookies';
import { CookieStore } from '@exchanger/common';
import { usePathname, useRouter } from 'next/navigation';
import i18nConfig from '@/i18nConfig';

export default function LanguageChanger() {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const cookieStore = useCookies();
  const router = useRouter();
  const currentPathname = usePathname();

  const handleChange = (newLocale: string) => {
    let newUrl;
    const searchParams = window.location.search;

    if (
      currentLocale === i18nConfig.defaultLocale &&
      !i18nConfig.prefixDefault
    ) {
      newUrl = '/' + newLocale + currentPathname + searchParams;
    } else {
      newUrl = currentPathname.replace(`/${currentLocale}`, `/${newLocale}`) + searchParams;
    }

    cookieStore.set(CookieStore.locale, newLocale);
    setIsOpen(false);
    window.location.href = newUrl;
  };

  const getFlagComponent = (locale: string) => {
    switch (locale) {
      case 'en':
        return <UsSvg width={21} height={15} />;
      case 'ru':
        return <RuSvg width={21} height={15} />;
      case 'es':
        return <EsSvg width={21} height={16} className="rounded-[3px]" />;
      case 'zh':
        return <ZhSvg width={21} height={16} className="rounded-[3px]" />;
      case 'fr':
        return <FrSvg width={21} height={16} className="rounded-[3px]" />;
      case 'hi':
        return <HiSvg width={21} height={16} className="rounded-[3px]" />;
      case 'ko':
        return <KoSvg width={21} height={16} className="rounded-[3px]" />;
      case 'de':
        return <DeSvg width={21} height={16} className="rounded-[3px]" />;
      case 'ja':
        return <JaSvg width={21} height={16} className="rounded-[3px]" />;
      case 'pt':
        return <PtSvg width={21} height={16} className="rounded-[3px]" />;
      case 'tr':
        return <TrSvg width={21} height={16} className="rounded-[3px]" />;
      case 'uk':
        return <UkSvg width={21} height={16} className="rounded-[3px]" />;
      default:
        return <UsSvg width={21} height={16} className="rounded-[3px]" />;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex items-center justify-center w-full rounded-md shadow-sm px-1 py-2 text-sm font-medium text-gray-700 md:px-4 md:py-1 md:text-base"
          id="options-menu"
          aria-haspopup="true"
          aria-label="change language"
          aria-expanded={isOpen ? 'true' : 'false'}
          onClick={() => setIsOpen(!isOpen)}
        >
          {getFlagComponent(currentLocale)}
          <ArrowSvg className={`ml-1 -mr-1 h-5 w-5 transform ${isOpen ? 'rotate-180' : 'rotate-0'}`} />
        </button>
      </div>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-1 w-[17rem] rounded-md shadow-lg bg-[#3a3939] ring-1 ring-black ring-opacity-5 focus:outline-none md:w-[17rem]">
          <div className="py-1 grid grid-cols-2 gap-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {[
              { locale: 'en', label: 'English', Svg: UsSvg },
              { locale: 'ru', label: 'Русский', Svg: RuSvg },
              { locale: 'es', label: 'Español', Svg: EsSvg },
              { locale: 'uk', label: 'Українська', Svg: UkSvg },
              { locale: 'zh', label: '中文', Svg: ZhSvg },
              { locale: 'fr', label: 'Français', Svg: FrSvg },
              { locale: 'hi', label: 'हिंदी', Svg: HiSvg },
              { locale: 'ko', label: '한국어', Svg: KoSvg },
              { locale: 'de', label: 'Deutsch', Svg: DeSvg },
              { locale: 'ja', label: '日本語', Svg: JaSvg },
              { locale: 'pt', label: 'Português', Svg: PtSvg },
              { locale: 'tr', label: 'Türkçe', Svg: TrSvg },
            ].map(({ locale, label, Svg }) => (
              <button
                key={locale}
                onClick={() => handleChange(locale)}
                aria-label={locale}
                className="flex items-center w-full px-4 py-2 text-sm text-white hover:bg-[#292e37]"
                role="menuitem"
              >
                <Svg width={21} height={16} className="mr-2 rounded-[3px]" />
                {label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
